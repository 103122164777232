@keyframes flicker {
    0% {
        opacity: 0;
    }
    3% {
        opacity: 0;
    }
    6% {
        opacity: 1;
    }
    7% {
        opacity: 1;
    }
    8% {
        opacity: 0;
    }
    9% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

#L {
    animation: flicker 6s infinite step-end;
}

#et {
    animation: flicker 5s infinite 2s step-end;
}

.profile {
    width: 100vw;
    min-height: 100vh;
    margin-top: 60px;
    margin-bottom: 40px;
    padding-top: 60px;
    padding-bottom: 40px;
    display: flex;
    justify-content: center;

    @media (--mediaTablet) {
        & {
            padding-top: 50px;
            padding-right: 80px;
            padding-left: 160px;
            height: auto;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }

    @media (--mediaMobile) {
        & {
            margin-top: 0;
            padding-top: 90px;
            padding-left: 25px;
            padding-right: 25px;
            overflow-x: hidden;
        }
    }

    @media (--mediaMobile), (max-height: 696px) {
        & {
            padding-right: var(--spaceOuter);
            padding-left: var(--spaceOuter);
        }
    }

    @media (max-width: 820px) and (max-height: 420px) {
        & {
            padding-right: var(--space4XL);
            padding-left: var(--space4XL);
        }
    }
}

.profile__content {
    display: grid;
    grid-template-columns: 1fr 50%;
    grid-column-gap: var(--space2XL);
    max-width: var(--maxWidthL);
    width: 100%;

    @media (--mediaTablet) {
        & {
            max-width: 600px;
            grid-template-columns: 100%;
        }
    }
}

.profile__column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    transform: translate3d(0, 0, 0);
}

.profile__title {
    white-space: nowrap;
    margin-bottom: var(--spaceL);
    opacity: 0;
    transition: opacity var(--durationXL) ease var(--durationM);
}

.profile__title--entered {
    opacity: 1;
}

.profile__description {
    margin-bottom: var(--spaceXL);
    opacity: 0;
    transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__description--entered {
    opacity: 1;
}

.profile__tag {
    margin-top: 220px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: var(--space4XL) 1fr;
    gap: 12px;
    align-items: center;

    @media (--mediaTablet) {
        & {
            margin-top: 30px;
        }
    }
}

.profile__tag-text {
    font-size: var(--fontSizeBodyS);
    font-weight: var(--fontWeightMedium);
    color: rgb(var(--rgbPrimary));
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: var(--bezierFastoutSlowin);
    transition-duration: var(--durationM);
    transition-delay: 1.3s;

    @media (--mediaUseMotion) {
        & {
            transition-property: opacity, transform;
            transform: translate3d(calc(var(--spaceM) * -1), 0, 0);
        }
    }
}

.profile__tag-text--entered {
    transform: none;
    opacity: 1;
}

@keyframes image-reveal {
    0% {
        transform: scale3d(0, 1, 1);
        transform-origin: left;
    }
    49% {
        transform: scale3d(1, 1, 1);
        transform-origin: left;
    }
    50% {
        transform: scale3d(1, 1, 1);
        transform-origin: right;
    }
    100% {
        transform: scale3d(0, 1, 1);
        transform-origin: right;
    }
}

.profile__image-wrapper {
    position: relative;
    max-width: 100%;
}

.profile__image {
    max-width: 100%;
    width: 960px;
    height: 560px;
    position: relative;

    &::before {
        content: '';
        background-color: rgb(var(--rgbAccent));
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale3d(0, 1, 1);
        transform-origin: left;
        z-index: 16;
    }

    & .portrait__canvas {
        opacity: 0;
    }

    @media (--mediaTablet) {
        & {
            width: 960px;
            height: 1120px;
        }
    }

    @media (--mediaMobile) {
        & {
            width: 480px;
            height: 560px;
        }
    }
}

.profile__image--entered {
    &::before {
        animation: image-reveal 1.8s var(--bezierFastoutSlowin) var(--delay);
        animation-iteration-count: 1;
    }

    & .portrait__canvas {
        animation: fade-in var(--durationM) ease var(--delay) forwards;
        animation-iteration-count: 1;
        animation-delay: 1s;
    }
}

.profile__svg {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate3d(50%, -20%, 0);
    height: 100%;
    z-index: 32;
    opacity: 0;
    transition: opacity var(--durationM) ease var(--durationL);
    fill: var(--colorTextTitle);
}

.profile__svg--entered {
    opacity: 1;
}

.profile__button {
    opacity: 0;
    transition: opacity var(--durationXL) ease var(--durationL);
}

.profile__button--entered {
    opacity: 1;
}
