.portrait_alt {
    width: 50rem;
    height: 45rem;
    position: relative;
}

.portrait__canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}